import React, { Fragment, useState, useEffect } from "react";
import { SubLayout } from "@/containers/layout/LandingLayout";
import DashboardButton from "@/atoms/DashboardButton";
import Link from "next/link";
import routes from "@/constants/routes";
import { getArticlesPaginatedQuery } from "@/services/cms/queries";
import { StrapiRequestParams } from "strapi-sdk-js";
import { Article } from "@/services/cms/apiTypes";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import { Props } from "./types";
import SingleArticle from "../singleArticle/SingleArticle";

export const ARTICLES_DEFAULT_POPULATE = ["categories", "cover", "author", "author.avatar"];
const LatestArticles = ({ selectedCategory, excludeCategories }: Props) => {
  const { width } = useWindowSize();
  const [articles, setArticles] = useState<Article[]>([]);
  const [articlesParams, setArticlesParams] = useState<StrapiRequestParams>({});

  useEffect(() => {
    setArticlesParams({
      populate: ARTICLES_DEFAULT_POPULATE,
      sort: "addedOn:desc",
      ...((selectedCategory || excludeCategories) && {
        filters: {
          categories: {
            id: {
              ...(selectedCategory && {
                $eq: [selectedCategory],
              }),
              ...(excludeCategories && { $ne: excludeCategories }),
            },
          },
        },
      }),
    });
  }, [selectedCategory, excludeCategories]);

  const {
    isLoading: isArticleLoding,
    data: articleResponse,
    hasNextPage,
    fetchNextPage,
  } = getArticlesPaginatedQuery(articlesParams);

  useEffect(() => {
    if (!isArticleLoding) {
      setArticles(articleResponse?.pages.flatMap((page) => page.data) || []);
    }
  }, [isArticleLoding, articleResponse]);

  function formatISODateToReadableDate(inputDateString: string) {
    const date = new Date(inputDateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDateString = `${monthNames[monthIndex]} ${day}, ${year}`;

    return formattedDateString;
  }

  return (
    <SubLayout allowOverflow>
      <h2 className="text-4xl font-bold text-center xl:text-5xl text-landing-blue my-11 xl:my-14">
        Read our latest articles
      </h2>
      <section className="flex flex-wrap items-center justify-between w-auto mt-9 max-w-[90rem] mb-5 md:mb-12 mx-auto lg:px-[3rem] xl:px-[3.75rem]">
        {articles.slice(0, 2).map((article, idx) => (
          <Fragment key={idx}>
            <div className={`flex flex-col w-full md:w-1/2 mb-8 md:px-4`}>
              <SingleArticle article={article as any} baseUrl={routes.blog} />
            </div>
          </Fragment>
        ))}
      </section>
      <Link href={routes.blog}>
        <div className="flex justify-center w-full md:w-auto mb-12 md:mb-20">
          <DashboardButton
            className="px-4 py-2 w-full md:w-auto rounded-sm md:rounded-lg"
            variant="submit"
          >
            See all articles
          </DashboardButton>
        </div>
      </Link>
    </SubLayout>
  );
};

export default LatestArticles;
